import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';

const routes: Routes = [
    { path: "", redirectTo: "/secure/charge", pathMatch: "full" },
	{ path: "start", loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule) },
    { path: "oauth-callback", loadChildren: () => import('./pages/oauth-callback/oauth-callback.module').then(m => m.OauthCallbackPageModule) },
    { path: "secure", loadChildren: () => import('./pages/secure/secure.module').then(m => m.SecurePageModule), canActivate: [LoggedInGuard] },
    { path: "logout", loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutPageModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  providers: [LoggedInGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
