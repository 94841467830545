import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, from } from 'rxjs';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    private refreshing_access_token: boolean = false;
    private access_token_subject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        public alertController: AlertController,
        private router: Router,
        private authService: AuthService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                // client side / network error
                if (error.error instanceof Error) {
                    console.log(error);
                    return throwError(error);
                }
                // server side error
                else {
                    // unauthorised (attempt to refresh access token, otherwise boot to logout)
                    if(error.status == 401) {
                        if(!this.refreshing_access_token) {
                            this.refreshing_access_token = true;
                            this.access_token_subject.next(null);
                            return from(this.authService.renewAccessToken()).pipe(
                                switchMap(response => {
                                    this.refreshing_access_token = false;
                                    this.access_token_subject.next(response['access_token']);
                                    return next.handle(this.switchAccessToken(request,response['access_token']));
                                }),
                                catchError((error) => {
                                    // We don't bother handling 503 as we assume original request would have caught that. 
                                    // If they get to here, server is up, and a 401 has already occurred
                                    this.refreshing_access_token = false;
                                    // if unable to get new token, boot them
                                    if(error.url.includes('/merchant/oauth2/token')) {
                                        this.router.navigate(['logout'], { replaceUrl: true }).then(() => {
                                            window.location.reload();
                                        });
                                    }
                                    // original request may produce server error, so handle here
                                    else if(error.status == 500) {
                                        this.presentAlert('Server error',"Unfortunately something went wrong on the server. We're aware of the issue and on the case.");
                                    }
                                    return throwError(error);
                                })
                            );
                        }
                        else {
                            return this.access_token_subject.pipe(
                                filter(access_token => access_token != null),
                                take(1),
                                switchMap(access_token => {
                                    return next.handle(this.switchAccessToken(request, access_token));
                                })
                            );
                        }
                    }
                    // internal server error
                    else if(error.status == 500) {
                        this.presentAlert('Server error',"Unfortunately something went wrong on the server. We're aware of the issue and on the case.");
                        return throwError(error);
                    }
                    // bad gateway
                    else if (error.status === 502) {
                        this.presentAlert('Server temporarily unavailable', "Unfortunately the server isn't available at this time, please retry again shortly.");
                        return throwError(error);
                    }                        
                    // server temporarily unavailable (i.e. server restart, object reload)
                    else if(error.status == 503) {
                        this.presentAlert('Server temporarily unavailable',"Unfortunately the server isn't available at this time.");
                        return throwError(error);
                    }
                    else {
                        return throwError(error);
                    }
                }
            })
        );
    }

    switchAccessToken(request: HttpRequest<any>, access_token: string): HttpRequest<any> {
        return request.clone({
            setParams: {
                'access_token': access_token
            }
        });
    }

    async presentAlert(sub_header,message) {
        const alert = await this.alertController.create({
            header: 'Problem',
            subHeader: sub_header,
            message: message,
            buttons: ['OK']
        });
        await alert.present();
    }
}
