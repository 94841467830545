import { Component, OnInit } from '@angular/core';
import { PusherService } from './services/pusher.service';
import { ObserverService } from './services/observer.service';
import { StorageService } from './services/storage.service';
import { APIService } from './services/api.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Network } from '@capacitor/network';
import { Platform, LoadingController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

    pusher_channel = null;
    spinner = null;

    constructor (
        private platform: Platform,
        private pusherService: PusherService,
        private observerService: ObserverService,
        private storageService: StorageService,
        private apiService: APIService,
        private router: Router,
        public loadingController: LoadingController,
        public alertController: AlertController,
    ) {}

    initializeApp() {
        this.platform.ready().then(() => {
            Network.addListener("networkStatusChange", (status) => {
                if (status.connected) {
                    this.initializePusher();
                }
            });
            // on device (not browser)
            if (this.platform.is('capacitor')) {
                StatusBar.setStyle({ style: Style.Dark });
                App.addListener('appUrlOpen', data => {
                    if (data.url.indexOf('nz.co.fundpay.merchant://oauth-callback') === 0) {
                        this.router.navigateByUrl('/oauth-callback' + data.url.substr(data.url.indexOf('?')), { replaceUrl: true });
                        Browser.close();
                    }
                });
                App.addListener("appStateChange", ({ isActive }) => {
                    if (isActive) {
                        this.initializePusher();
                        this.loadAccount();
                    }
                });
                SplashScreen.hide();
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    ngOnInit() {

        this.observerService.subscribe("logged_in", () => {
            this.initializePusher();
        });

        this.observerService.subscribe("access_token_changed", () => {
            this.initializePusher();
        });

        this.observerService.subscribe("logged_out", (data) => {
            this.pusherService.disconnect();
        });

        this.initializePusher();

    }

    initializePusher() {

        this.storageService.get('access_token').then((access_token) => {

            if(access_token) {

                this.storageService.get('merchant_id').then((merchant_id) => {

                    if(merchant_id) {

                        this.pusher_channel = this.pusherService.init(merchant_id, access_token);

                        this.pusher_channel.bind("transaction_intent_approved", (transaction_intent) => {
                            this.observerService.publish("transaction_intent_approved", transaction_intent);
                        });

                        this.pusher_channel.bind("transaction_intent_declined", (transaction_intent) => {
                            this.observerService.publish("transaction_intent_declined", transaction_intent);
                        });

                        this.pusher_channel.bind("account_updated", (account) => {
                            this.observerService.publish("account_updated", account);
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    loadAccount() {
        return new Promise<void>((resolve, reject) => {
            this.apiService.getAccount().then((account) => {
                if (account) {
                    this.observerService.publish("account_updated", account);
                    resolve();
                }
            })
            .catch(() => {
                // fail silently
                resolve();
            });
        })
    }
    
}
