import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AccountPosIntegrationVendPage } from './account-pos-integration-vend.page';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
        FontAwesomeModule,
        CommonModule,
        FormsModule,
        IonicModule,
    ],
    declarations: [AccountPosIntegrationVendPage]
})
export class AccountPosIntegrationVendPageModule { }
