<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="close()">
                <fa-icon [icon]="['fas', 'times']" size="lg" [fixedWidth]="true" slot="start"></fa-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">Refund</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="validateInputs()">
                <fa-icon [icon]="['fas', 'check']" size="lg" [fixedWidth]="true" slot="start"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list class="form-wrapper">
        <ion-item lines="full">
            <ion-label position="stacked">Amount</ion-label>
            <ion-input type="text" inputmode="decimal" maxlength="10" [(ngModel)]="params['price']"></ion-input>
        </ion-item>
        <ion-item lines="full">
            <ion-label position="stacked">Note for us</ion-label>
            <ion-textarea type='text' inputmode="text" maxlength="1000" rows="1" autoGrow="true" [(ngModel)]="params['merchant_note']"></ion-textarea>
        </ion-item>
        <ion-item lines="full">
            <ion-label position="stacked">Note to customer</ion-label>
            <ion-textarea type='text' inputmode="text" maxlength="1000" rows="1" autoGrow="true" [(ngModel)]="params['consumer_note']"></ion-textarea>
        </ion-item>
    </ion-list>
</ion-content>
