import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { APIService } from './api.service';
import { ObserverService } from './observer.service';

@Injectable()

export class AuthService {

    constructor(
        private apiService: APIService,
        private storageService: StorageService,
        private observerService: ObserverService
    ) {}

    public clearSession() {
        this.storageService.clear();
    }

    public isLoggedIn(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if(access_token) {
                    this.storageService.get('refresh_token').then((refresh_token) => {
                        if(refresh_token) {
                            resolve(true);
                        }
                        else {
                            reject(false);
                        }
                    }).catch((error) => {
                        reject(false);
                    });
                }
                else {
                    reject(false);
                }
            }).catch((error) => {
                reject(false);
            });
        });
    }

    public renewAccessToken() {
        return new Promise((resolve, reject) => {
            this.storageService.get('refresh_token').then((refresh_token) => {
                if(refresh_token) {
                    let params = {};
                    params['grant_type'] = 'refresh_token';
                    params['refresh_token'] = refresh_token;
                    this.apiService.getAccessToken(params).then((response) => {
                        this.storageService.set('access_token', response['access_token']).then(() => {
                            this.observerService.publish('access_token_changed', {});
                            resolve(response);
                        });                        
                    },
                    error => {
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public revokeAccessToken() {
        return new Promise<void>((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if(access_token) {
                    this.apiService.revokeAccessToken(access_token).then(() => {
                        resolve();
                    }).catch(() => {
                        resolve();
                    });
                }
                else {
                    resolve();
                }
            }).catch(() => {
                resolve();
            });
        });
    }
}
