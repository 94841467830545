import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import Pusher from 'pusher-js';

@Injectable()
export class PusherService {

    pusher = null;
    pusher_channel = null;

    constructor (
        public http: HttpClient
    ) {}

    public init(merchant_id, access_token) {

        this.disconnect();

        this.pusher = new Pusher('2b1878412f9ae108fe1c', {
            cluster: 'ap4',
            //authEndpoint: 'https://api.fundpay.co.nz/merchant/pusherauth?access_token=' + access_token
        });
        //this.pusher_channel = this.pusher.subscribe('private-merchant-' + merchant_id);
        this.pusher_channel = this.pusher.subscribe('merchant-' + merchant_id); 

        return this.pusher_channel;

    }

    public disconnect() {
        if (this.pusher) {
            this.pusher.disconnect();
        }
    }

}
