<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="close()">
                <fa-icon [icon]="['fas', 'times']" size="lg" [fixedWidth]="true" slot="start"></fa-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">Edit details</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="validateInputs()">
                <fa-icon [icon]="['fas', 'check']" size="lg" [fixedWidth]="true" slot="start"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list class="form-wrapper">
        <ion-item-group>
            <ion-item-divider>
                <ion-label>Trading details</ion-label>
            </ion-item-divider>
            <ion-item lines="full">
                <ion-label position="stacked">Trading name</ion-label>
                <ion-input type="text" inputmode="text" maxlength="50" [(ngModel)]="params['name']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">Phone number</ion-label>
                <ion-input type="tel" inputmode="text" maxlength="20" [(ngModel)]="params['phone_number']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">Email address</ion-label>
                <ion-input type="email" inputmode="email" maxlength="320" [(ngModel)]="params['email_address']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">Website URL <span class="optional">optional</span></ion-label>
                <ion-input type="url" inputmode="url" maxlength="2000" [(ngModel)]="params['website_url']"></ion-input>
            </ion-item>
        </ion-item-group>
        <ion-item-group>
            <ion-item-divider>
                <ion-label>Physical address</ion-label>
            </ion-item-divider>
            <ion-item lines="full">
                <ion-label position="stacked">Line 1</ion-label>
                <ion-input type="text" inputmode="text" maxlength="100" [(ngModel)]="params['physical_address_line_1']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">Line 2 <span class="optional">optional</span></ion-label>
                <ion-input type="text" inputmode="text" maxlength="100" [(ngModel)]="params['physical_address_line_2']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">Town / city</ion-label>
                <ion-input type="text" inputmode="text" maxlength="50" [(ngModel)]="params['physical_address_town_city']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">ZIP / postcode</ion-label>
                <ion-input type="tel" inputmode="text" maxlength="4" [(ngModel)]="params['physical_address_postcode']"></ion-input>
            </ion-item>            
        </ion-item-group>
        <ion-item-group>            
            <ion-item-divider>
                <ion-label>Business details</ion-label>
            </ion-item-divider>
            <ion-item lines="full">
                <ion-label position="stacked">Registered business name</ion-label>
                <ion-input type="text" inputmode="text" maxlength="50" [(ngModel)]="params['business_name']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">NZ Business Number (NZBN)</ion-label>
                <ion-input type="tel" inputmode="tel" maxlength="13" [(ngModel)]="params['nzbn']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">Business type</ion-label>
                <ion-select cancelText="Clear" okText="OK" [(ngModel)]="params['business_type_id']" (ionCancel)="clearBusinessType()" interface="action-sheet">
                    <ion-select-option *ngFor="let business_type of business_types" value="{{business_type.id}}">{{business_type.name}}</ion-select-option>
                </ion-select>
            </ion-item>            
            <ion-item lines="full">
                <ion-label position="stacked">Bank account number</ion-label>
                <ion-input #bank_account_number type="tel" inputmode="text" maxlength="18" [(ngModel)]="params['bank_account_number']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">Contact person</ion-label>
                <ion-input type="text" inputmode="text" maxlength="50" [(ngModel)]="params['contact_person']"></ion-input>
            </ion-item>            
            <ion-item lines="full">
                <ion-label position="stacked">Email offers and latest news <span class="optional">optional</span></ion-label>
                <ion-toggle [(ngModel)]="params['subscribed_marketing']"></ion-toggle>
            </ion-item>            
        </ion-item-group>
        <ion-item-group>            
            <ion-item-divider>
                <ion-label>Billing address</ion-label>
            </ion-item-divider>
            <ion-item lines="full">
                <ion-label position="stacked">Line 1</ion-label>
                <ion-input type="text" inputmode="text" maxlength="100" [(ngModel)]="params['billing_address_line_1']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">Line 2 <span class="optional">optional</span></ion-label>
                <ion-input type="text" inputmode="text" maxlength="100" [(ngModel)]="params['billing_address_line_2']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">Town / city</ion-label>
                <ion-input type="text" inputmode="text" maxlength="50" [(ngModel)]="params['billing_address_town_city']"></ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">ZIP / postcode</ion-label>
                <ion-input type="tel" inputmode="text" maxlength="4" [(ngModel)]="params['billing_address_postcode']"></ion-input>
            </ion-item>
        </ion-item-group>
    </ion-list>
</ion-content>
