import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { StorageService } from './services/storage.service';
import { ObserverService } from './services/observer.service';
import { GlobalVariableService } from './services/globalvariable.service';
import { APIService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { PusherService } from './services/pusher.service';
import { TransactionSearchPageModule } from './modals/transaction-search/transaction-search.module';
import { AccountEditPageModule } from './modals/account-edit/account-edit.module'
import { AccountPosIntegrationVendPageModule } from './modals/account-pos-integration-vend/account-pos-integration-vend.module'
import { AccountOptionsComponent } from './popovers/account-options/account-options.component'
import { TransactionOptionsComponent } from './popovers/transaction-options/transaction-options.component'
import { TransactionRefundPageModule } from './modals/transaction-refund/transaction-refund.module';



@NgModule({
  declarations: [
    AppComponent,
    AccountOptionsComponent,
    TransactionOptionsComponent
  ],
  entryComponents: [
    AccountOptionsComponent,
    TransactionOptionsComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    TransactionSearchPageModule,
    AccountEditPageModule,
    AccountPosIntegrationVendPageModule,
    TransactionRefundPageModule,
  ],
  providers: [
    StorageService,
    ObserverService,
    GlobalVariableService,
    APIService,
    AuthService,
    PusherService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true
    }
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(library: FaIconLibrary) {
      library.addIconPacks(fas);
      library.addIconPacks(far);
      library.addIconPacks(fab);
      library.addIconPacks(fal);
  }
}
