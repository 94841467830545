import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { GlobalVariableService } from '../services/globalvariable.service';

@Injectable()
export class APIService {

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        public globalVariableService: GlobalVariableService
    ) { }

    public addConsumerTransaction(data) {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    let form_data = new FormData();
                    form_data.append('originating_transaction_id', data.originating_transaction_id);
                    form_data.append('price', data.price);
                    form_data.append('consumer_id', data.consumer_id);
                    form_data.append('type', data.type);
                    if(data.merchant_note != undefined) {
                        form_data.append('merchant_note', data.merchant_note);
                    }
                    if (data.consumer_note != undefined) {
                        form_data.append('consumer_note', data.consumer_note);
                    }
                    const options = {
                        params: {
                            access_token: access_token
                        },
                    };
                    this.http.post('https://api.fundpay.co.nz/merchant/1.0/consumer-transactions/', form_data, options).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getAccount() {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    let params = {};
                    params['access_token'] = access_token;
                    this.http.get('https://api.fundpay.co.nz/merchant/1.0/account', { params: params }).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getBusinessTypes() {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    let params = {};
                    params['access_token'] = access_token;
                    this.http.get('https://api.fundpay.co.nz/merchant/1.0/business-types', { params: params }).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getConsumerTransactionIntent(code) {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    let params = {};
                    params['access_token'] = access_token;
                    this.http.get('https://api.fundpay.co.nz/merchant/1.0/consumer-transaction-intents/code:' + code, { params: params }).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getConsumerTransaction(id) {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    let params = {};
                    params['access_token'] = access_token;
                    this.http.get('https://api.fundpay.co.nz/merchant/1.0/consumer-transactions/' + id, { params: params }).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getConsumerTransactions(params) {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    params = Object.assign({}, params);
                    params['access_token'] = access_token;
                    this.http.get('https://api.fundpay.co.nz/merchant/1.0/consumer-transactions', { params: params }).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getPayout(id) {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    let params = {};
                    params['access_token'] = access_token;
                    this.http.get('https://api.fundpay.co.nz/merchant/1.0/payouts/' + id, { params: params }).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getPayouts(params) {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    params = Object.assign({}, params);
                    params['access_token'] = access_token;
                    this.http.get('https://api.fundpay.co.nz/merchant/1.0/payouts', { params: params }).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public getAccessToken(params) {
        return new Promise((resolve, reject) => {
            let data = new FormData();
            data.append('grant_type', params.grant_type);
            if (params.code) {
                data.append('code', params.code);
            }
            if (params.refresh_token) {
                data.append('refresh_token', params.refresh_token);
            }
            data.append('client_id', this.globalVariableService.api_client_id);
            data.append('client_secret', this.globalVariableService.api_client_secret);
            data.append('redirect_uri', this.globalVariableService.api_redirect_uri);
            this.http.post('https://api.fundpay.co.nz/merchant/oauth2/token', data).subscribe(response => {
                resolve(response);
            },
            error => {
                console.error(error)
                reject(error);
            });
        });
    }

    public releaseConsumerTransactionsForPayout() {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    let form_data = new FormData();
                    const options = {
                        params: {
                            access_token: access_token
                        },
                        headers: {
                            'x-http-method-override': 'patch'
                        }
                    };
                    this.http.post('https://api.fundpay.co.nz/merchant/1.0/consumer-transactions/release-for-payout', form_data, options).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }    

    public revokeAccessToken(access_token) {
        return new Promise((resolve, reject) => {
            let data = new FormData();
            data.append('token_type_hint', 'access_token');
            data.append('token', access_token);
            data.append('client_id', this.globalVariableService.api_client_id);
            data.append('client_secret', this.globalVariableService.api_client_secret);
            this.http.post('https://api.fundpay.co.nz/merchant/oauth2/revoke', data).subscribe(response => {
                resolve(response);
            },
            error => {
                console.error(error)
                reject(error);
            });
        });
    }

    public updateConsumerTransactionIntent(transaction_intent_id, data) {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {
                if (access_token) {
                    let form_data = new FormData();
                    form_data.append('status', data['status']);
                    form_data.append('price', data['price']);
                    const options = {
                        params: {
                            access_token: access_token
                        },
                        headers: {
                            'x-http-method-override': 'patch'
                        }
                    };
                    this.http.post('https://api.fundpay.co.nz/merchant/1.0/consumer-transaction-intents/id:' + transaction_intent_id, form_data, options).subscribe(response => {
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });
                }
                else {
                    reject();
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }


    public updateAccount(data) {
        return new Promise((resolve, reject) => {
            this.storageService.get('access_token').then((access_token) => {

                if (access_token) {

                    let form_data = new FormData();

                    if (data.name != undefined) {
                        form_data.append('name', data.name);
                    }
                    if (data.business_name != undefined) {
                        form_data.append('business_name', data.business_name);
                    }
                    if (data.nzbn != undefined) {
                        form_data.append('nzbn', data.nzbn);
                    }
                    if (data.business_type_id != undefined) {
                        form_data.append('business_type_id', data.business_type_id);
                    }
                    if (data.contact_person != undefined) {
                        form_data.append('contact_person', data.contact_person);
                    }
                    if (data.email_address != undefined) {
                        form_data.append('email_address', data.email_address);
                    }
                    if (data.phone_number != undefined) {
                        form_data.append('phone_number', data.phone_number);
                    }
                    if (data.website_url != undefined) {
                        form_data.append('website_url', data.website_url);
                    }
                    if (data.bank_account_number != undefined) {
                        form_data.append('bank_account_number', data.bank_account_number);
                    }
                    if (data.billing_address_line_1 != undefined) {
                        form_data.append('billing_address_line_1', data.billing_address_line_1);
                    }
                    if (data.billing_address_line_2 != undefined) {
                        form_data.append('billing_address_line_2', data.billing_address_line_2);
                    }
                    if (data.billing_address_town_city != undefined) {
                        form_data.append('billing_address_town_city', data.billing_address_town_city);
                    }
                    if (data.billing_address_postcode != undefined) {
                        form_data.append('billing_address_postcode', data.billing_address_postcode);
                    }
                    if (data.physical_address_line_1 != undefined) {
                        form_data.append('physical_address_line_1', data.physical_address_line_1);
                    }
                    if (data.physical_address_line_2 != undefined) {
                        form_data.append('physical_address_line_2', data.physical_address_line_2);
                    }
                    if (data.physical_address_town_city != undefined) {
                        form_data.append('physical_address_town_city', data.physical_address_town_city);
                    }
                    if (data.physical_address_postcode != undefined) {
                        form_data.append('physical_address_postcode', data.physical_address_postcode);
                    }
                    if (data.subscribed_marketing != undefined) {
                        form_data.append('subscribed_marketing', data.subscribed_marketing);
                    }
                    if (data.send_verify_email_address_email != undefined) {
                        form_data.append('send_verify_email_address_email', data.send_verify_email_address_email);
                    }

                    const options = {
                        params: {
                            access_token: access_token
                        },
                        headers: {
                            'x-http-method-override': 'patch'
                        }
                    };

                    this.http.post('https://api.fundpay.co.nz/merchant/1.0/account/', form_data, options).subscribe(response => {
                        console.log(response)
                        resolve(response);
                    },
                    error => {
                        console.error(error)
                        reject(error);
                    });

                }
                else {
                    reject();
                }

            }).catch((error) => {
                reject(error);
            });
        });
    }



}
