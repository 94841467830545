import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { APIService } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import { ObserverService } from '../../services/observer.service';
// import { Inputmask } from "inputmask";

@Component({
    selector: 'app-account-edit',
    templateUrl: './account-edit.page.html',
    styleUrls: ['./account-edit.page.scss'],
})
export class AccountEditPage implements OnInit {


    // @ViewChild('bank_account_number') bank_account_number: ElementRef;

    params = {};
    account_id = null;
    account = null;
    business_types = null;
    spinner = null;

    constructor(
        private router: Router,
        private modalController: ModalController,
        private alertController: AlertController,
        private navParams: NavParams,
        private loadingController: LoadingController,
        private authService: AuthService,
        private apiService: APIService,
        private observerService: ObserverService,

    ) { }

    ngOnInit() {

        this.loadAccount().then(() => {
            if (this.account.name) {
                this.params["name"] = this.account["name"];
            } else {
                this.params["name"] = "";
            }
            if (this.account.business_name) {
                this.params["business_name"] = this.account["business_name"];
            } else {
                this.params["business_name"] = "";
            }            
            if (this.account.nzbn) {
                this.params["nzbn"] = this.account["nzbn"].toString();
            } else {
                this.params["nzbn"] = "";
            }
            if (this.account.business_type && this.account.business_type.id) {
                this.params["business_type_id"] = this.account.business_type.id;
            } else {
                this.params["business_type_id"] = "";
            }
            if (this.account.contact_person) {
                this.params["contact_person"] = this.account["contact_person"];
            } else {
                this.params["contact_person"] = "";
            }
            if (this.account.email_address) {
                this.params["email_address"] = this.account["email_address"];
            } else {
                this.params["email_address"] = "";
            }
            if (this.account.phone_number) {
                this.params["phone_number"] = this.account["phone_number"].toString();
            } else {
                this.params["phone_number"] = "";
            }
            if (this.account.website_url) {
                this.params["website_url"] = this.account["website_url"];
            } else {
                this.params["website_url"] = "";
            }
            if (this.account.bank_account_number) {
                this.params["bank_account_number"] = this.account["bank_account_number"].toString();
            } else {
                this.params["bank_account_number"] = "";
            }
            if (this.account.billing_address && this.account.billing_address.line_1) {
                this.params["billing_address_line_1"] = this.account.billing_address.line_1;
            } else {
                this.params["billing_address_line_1"] = "";
            }
            if (this.account.billing_address && this.account.billing_address.line_2) {
                this.params["billing_address_line_2"] = this.account.billing_address.line_2;
            } else {
                this.params["billing_address_line_2"] = "";
            }
            if (this.account.billing_address && this.account.billing_address.town_city) {
                this.params["billing_address_town_city"] = this.account.billing_address.town_city;
            } else {
                this.params["billing_address_town_city"] = "";
            }
            if (this.account.billing_address && this.account.billing_address.postcode) {
                this.params["billing_address_postcode"] = this.account.billing_address.postcode.toString();
            } else {
                this.params["billing_address_postcode"] = "";
            }
            if (this.account.physical_address && this.account.physical_address.line_1) {
                this.params["physical_address_line_1"] = this.account.physical_address.line_1;
            } else {
                this.params["physical_address_line_1"] = "";
            }
            if (this.account.physical_address && this.account.physical_address.line_2) {
                this.params["physical_address_line_2"] = this.account.physical_address.line_2;
            } else {
                this.params["physical_address_line_2"] = "";
            }
            if (this.account.physical_address && this.account.physical_address.town_city) {
                this.params["physical_address_town_city"] = this.account.physical_address.town_city;
            } else {
                this.params["physical_address_town_city"] = "";
            }
            if (this.account.physical_address && this.account.physical_address.postcode) {
                this.params["physical_address_postcode"] = this.account.physical_address.postcode.toString();
            } else {
                this.params["physical_address_postcode"] = "";
            }
            this.params["subscribed_marketing"] = this.account["subscribed_marketing"];
        });

        this.loadBusinessTypes();

    }

    loadAccount() {
        return new Promise<void>((resolve, reject) => {
            this.authService.isLoggedIn().then(() => {
                this.apiService.getAccount().then((account) => {
                    if (account) {
                        this.account = account;
                        resolve();
                    }
                })
                .catch((error) => {
                    if (error.status === 400) {
                        this.presentAlert("Problem", "Unknown error", "Unfortunately something went wrong when trying to get your account.");
                    } else if (error.status === 403) {
                        this.presentAlert("Problem", "Insufficient permissions", "You don't have permission to view your account.");
                    } else if (error.status === 404 || error.status === 0) {
                        this.presentAlert("Problem", "Unavailable", "Unfortunately we're unable to retrieve your account at this time.");
                    }
                    reject();
                });
            })
            .catch(() => {
                this.router.navigate(["logout"], { replaceUrl: true }).then(() => {
                    window.location.reload();
                });
            });
        })
    }

    loadBusinessTypes() {
        return new Promise<void>((resolve, reject) => {
            this.authService.isLoggedIn().then(() => {
                this.apiService.getBusinessTypes().then((business_types) => {
                    if (business_types) {
                        this.business_types = business_types;
                        resolve();
                    }
                })
                .catch((error) => {
                    if (error.status === 400) {
                        this.presentAlert("Problem", "Unknown error", "Unfortunately something went wrong when trying to get the business types.");
                    } else if (error.status === 403) {
                        this.presentAlert("Problem", "Insufficient permissions", "You don't have permission to view your business types.");
                    } else if (error.status === 404 || error.status === 0) {
                        this.presentAlert("Problem", "Unavailable", "Unfortunately we're unable to retrieve the business types at this time.");
                    }
                    reject();
                });
            })
            .catch(() => {
                this.router.navigate(["logout"], { replaceUrl: true }).then(() => {
                    window.location.reload();
                });
            });
        })
    }

    validateInputs() {

        let validated = true;

        let email_address_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let url_regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        let clean_bank_acccount_number = this.params['bank_account_number'].replaceAll('-', '');

        if (this.params['name'] == null || this.params['name'].trim() === "") {
            this.presentAlert("Problem", "Trading name required", "");
            validated = false;
        } else if (this.params['business_name'] == null || this.params['business_name'].trim() === "") {
            this.presentAlert("Problem", "Registerd business name required", "");
            validated = false;
        } else if(this.params['nzbn'] == null || this.params['nzbn'].trim() === "") {
            this.presentAlert("Problem", "NZ business number required", "");
            validated = false;
        } else if(isNaN(this.params['nzbn']) || this.params['nzbn'].length != 13) {
            this.presentAlert("Problem", "Invalid NZ business number", "");
            validated = false;
        } else if(this.params['contact_person'] == null || this.params['contact_person'].trim() === "") {
            this.presentAlert("Problem", "Contact person required", "");
            validated = false;
        } else if (this.params['phone_number'] == null || this.params['phone_number'].trim() === "") {
            this.presentAlert("Problem", "Phone number required", "");
            validated = false;
        } else if(this.params['email_address'] == null || this.params['email_address'].trim() === "") {
            this.presentAlert("Problem", "Email address required", "");
            validated = false;
        } else if (this.params['email_address'].length && !email_address_regex.test(String(this.params['email_address']).toLowerCase())) {
            this.presentAlert("Problem", "Invalid email address", "");
            validated = false;
        } else if (this.params['website_url'].length && !url_regex.test(String(this.params['website_url']).toLowerCase())) {
            this.presentAlert("Problem", "Invalid website URL", "");
            validated = false;
        } else if (this.params['bank_account_number'] == null || this.params['bank_account_number'].trim() === "") {
            this.presentAlert("Problem", "Bank account number required", "");
            validated = false;
        } else if (isNaN(clean_bank_acccount_number) || clean_bank_acccount_number.length != 15) {
            this.presentAlert("Problem", "Invalid bank account number", "");
            validated = false;
        } else if (this.params['billing_address_line_1'] == null || this.params['billing_address_line_1'].trim() === "") {
            this.presentAlert("Problem", "Billing address line 1 required", "");
            validated = false;
        } else if (this.params['billing_address_town_city'] == null || this.params['billing_address_town_city'].trim() === "") {
            this.presentAlert("Problem", "Billing address town/city required", "");
            validated = false;
        } else if (this.params['billing_address_postcode'] == null || this.params['billing_address_postcode'].trim() === "") {
            this.presentAlert("Problem", "Billing address postcode required", "");
            validated = false;
        } else if (isNaN(this.params['billing_address_postcode']) || this.params['billing_address_postcode'].length != 4) {
            this.presentAlert("Problem", "Invalid billing address postcode", "");
            validated = false;
        } else if (this.params['physical_address_postcode'].length && (isNaN(this.params['physical_address_postcode']) || this.params['physical_address_postcode'].length != 4)) {
            this.presentAlert("Problem", "Invalid physical address postcode", "");
            validated = false;
        }

        if (validated) {

            //strip any dashes before passing to db
            this.params['bank_account_number'] = this.params['bank_account_number'].replaceAll('-', '');

            this.updateAccount();

        }
    }

     updateAccount() {
        this.presentSpinner("Updating account").then(() => {
            this.authService.isLoggedIn().then(() => {
                this.apiService.updateAccount(this.params).then((account) => {
                    if (account) {
                        this.observerService.publish("account_updated", account);
                    }
                    this.spinner.dismiss();
                    this.modalController.dismiss();
                })
                .catch((error) => {
                    if (error.status === 400) {
                        if (error.error.errors[0] == "email_address_not_unique") {
                            this.presentAlert("Problem", "Email address is not unique", "This email address has already been used and cannot be used again");
                        } else {
                            this.presentAlert("Problem", "Unknown error", "Unfortunately something went wrong when trying to update this account.");
                        }
                    } else if (error.status === 403) {
                        this.presentAlert("Problem", "Insufficient permissions", "You don't have permission to update this account.");
                    } else if (error.status === 404 || error.status === 0) {
                        this.presentAlert("Problem", "Unavailable", "Unfortunately we're unable to update this account at this time.");
                    }
                    this.spinner.dismiss();
                });
            })
            .catch(() => {
                this.spinner.dismiss();
                this.router.navigate(["logout"], { replaceUrl: true }).then(() => {
                    window.location.reload();
                });
            });
        });
    }

    clearBusinessType() {
        this.params["business_type_id"] = "";
    }

    close() {
        this.modalController.dismiss();
    }

    async presentAlert(header, sub_header, message) {
        const alert = await this.alertController.create({
            header: header,
            subHeader: sub_header,
            message: message,
            buttons: ["OK"],
        });
        await alert.present();
    }

    async presentSpinner(message) {
        this.spinner = await this.loadingController.create({
            message: message,
            showBackdrop: false,
            cssClass: 'loading-spinner'
        });
        await this.spinner.present();
    }

}
