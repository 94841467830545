import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { APIService } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import { ObserverService } from '../../services/observer.service';
import { Decimal } from 'decimal.js';

@Component({
    selector: 'app-transaction-refund',
    templateUrl: './transaction-refund.page.html',
    styleUrls: ['./transaction-refund.page.scss'],
})
export class TransactionRefundPage implements OnInit {

    params = {
        originating_transaction_id: null,
        price: null,
        type: 4,
        consumer_id: null,
        merchant_note: null,
        consumer_note: null
    };

    originating_transaction = null;
    spinner = null;

    constructor(
        private router: Router,
        private modalController: ModalController,
        private alertController: AlertController,
        private navParams: NavParams,
        private loadingController: LoadingController,
        private authService: AuthService,
        private apiService: APIService,
        private observerService: ObserverService,

    ) { }

    ngOnInit() {

        this.params.originating_transaction_id = this.navParams.get('transaction_id');

        this.presentSpinner("Loading transaction").then(() => {
            this.loadTransaction().then(() => {
                this.spinner.dismiss();
            })
            .catch(() => {
                this.spinner.dismiss();
            })
        })

    }

    loadTransaction() {
        const promise = new Promise<void>((resolve, reject) => {
                this.authService.isLoggedIn().then(() => {
                    this.apiService.getConsumerTransaction(this.params.originating_transaction_id).then((originating_transaction) => {
                        if (originating_transaction) {
                            if(originating_transaction['type'] == 2) {
                                if(originating_transaction['released_for_merchant_payout'] == false) {
                                    if(originating_transaction['maximum_refund_price'] > 0) {
                                        this.params.price = originating_transaction['maximum_refund_price'];
                                        this.params.consumer_id = originating_transaction['consumer'].id;
                                        this.originating_transaction = originating_transaction;
                                    }
                                    else {
                                        this.modalController.dismiss();
                                    }
                                }
                                else {
                                    this.modalController.dismiss();
                                }
                            }
                            else {
                                this.modalController.dismiss();
                            }
                            resolve();
                        }
                    })
                    .catch((error) => {
                        if (error) {
                            if (error.status === 400) {
                                this.presentAlert("Problem", "Unknown error", "Unfortunately something went wrong when trying to get the transaction.");
                            } else if (error.status === 403) {
                                this.presentAlert("Problem", "Insufficient permissions", "You don't have permission to view a transaction.");
                            } else if (error.status === 404 || error.status === 0) {
                                this.presentAlert("Problem", "Unavailable", "Unfortunately we're unable to retrieve the transaction at this time.");
                            }
                        }
                        reject();
                    });
                })
                .catch(() => {
                    this.router.navigate(["logout"], { replaceUrl: true }).then(() => {
                        window.location.reload();
                    });
                });
        });
        return promise;
    }

    close() {
        this.modalController.dismiss();
    }

    validateInputs() {

        let validated = true;

        // remove any non numeric characters 
        this.params.price =  this.params.price.toString().replace(/[^0-9.]/g, '');

        // Limit decimal places on price
        let price_decimal_place_count = 0;
        let price = "";
        for (let i = 0; i < this.params.price.length; i++) {
            if (this.params.price[i] == '.') {
                price_decimal_place_count++;
                if (price_decimal_place_count > 1) {
                    price += '';
                } else {
                    price += this.params.price[i];
                }
            } else {
                price += this.params.price[i];
            }
        }
        this.params.price = price;

        if (this.params.price === '.' || this.params.price == null || this.params.price == "") {
            this.presentAlert("Problem", "Amount is invalid", "");
            validated = false;
        }        
        else {
            this.params.price = Number(new Decimal(this.params.price).toFixed(2));
            if (this.params.price < 0) {
                this.presentAlert("Problem", "Amount is invalid", "");
                validated = false;
            }
            else if (this.params.price > this.originating_transaction.maximum_refund_price) {
                this.presentAlert("Problem", "Amount too high", "Amount is greater than the amount left available to be refunded on the transaction");
                validated = false;
            }
        }

        if (validated) {
            this.addConsumerTransaction();
        }

    }

    addConsumerTransaction() {
        this.presentSpinner("Refunding").then(() => {
           
            this.authService.isLoggedIn().then(() => {
                console.log('Params');
                console.log(this.params);
                this.apiService.addConsumerTransaction(this.params).then(() => {                    
                    this.observerService.publish("transaction_added", {});
                    this.observerService.publish("transaction_updated", {});
                    this.spinner.dismiss(); 
                    this.modalController.dismiss();
                })
                .catch((error) => {
                    if (error.status === 400) {
                        if (error.error.errors[0] == "price_greater_than_maximum_refund_price") {
                            this.presentAlert("Problem", "Amount too high", "Amount is greater than the amount left available to be refunded on the transaction");
                        }
                        else {
                            this.presentAlert("Problem", "Unknown error", "Unfortunately something went wrong when trying to add this transaction.");
                        }
                    } else if (error.status === 403) {
                        this.presentAlert("Problem", "Insufficient permissions", "You don't have permission to add this transaction.");
                    } else if (error.status === 404 || error.status === 0) {
                        this.presentAlert("Problem", "Unavailable", "Unfortunately we're unable to add this transaction at this time.");
                    }
                    this.spinner.dismiss();
                });
            })
            .catch(() => {
                this.spinner.dismiss();
                this.router.navigate(["logout"], { replaceUrl: true }).then(() => {
                    window.location.reload();
                });
            });
        });
    }

    async presentAlert(header, sub_header, message) {
        const alert = await this.alertController.create({
            header: header,
            subHeader: sub_header,
            message: message,
            buttons: ["OK"],
        });
        await alert.present();
    }

    async presentSpinner(message) {
        this.spinner = await this.loadingController.create({
            message: message,
            showBackdrop: false,
            cssClass: 'loading-spinner'
        });
        await this.spinner.present();
    }

}
