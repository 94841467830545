import { Injectable } from '@angular/core';

@Injectable()
export class GlobalVariableService {
    public api_client_id:string = 'DA292B04-F8E7-478E-FFE71C5BFC951FB9';
    public api_client_secret:string = 'DA2A113D-F029-35CC-2584B07A2F36D33C';
    public api_redirect_uri:string = 'https://api.fundpay.co.nz/merchant/oauth2/callback.html';

    public version:string = "1.0.0";
}
