import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class LoggedInGuard implements CanActivate {

    constructor(
        private router: Router,
        public authService: AuthService
    ) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            this.authService.isLoggedIn().then(() => {
                resolve(true);
            }).catch(() => {
                this.router.navigate(['logout'], { replaceUrl: true });
                reject(false);
            });
        });
    }
}
