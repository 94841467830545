<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="close()">
                <fa-icon [icon]="['fas', 'times']" size="lg" [fixedWidth]="true" slot="start"></fa-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
            Search
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="filterTransactions()">
                <fa-icon [icon]="['fas', 'check']" size="lg" [fixedWidth]="true" slot="start"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list>
        <ion-item lines="full">
            <ion-label position="stacked">Transaction number</ion-label>
            <ion-input type="text" inputmode="text" maxlength="1000" [(ngModel)]="search_params['number']"></ion-input>
        </ion-item>
        <ion-item lines="full">
            <ion-label position="stacked">Customer first name</ion-label>
            <ion-input type="text" inputmode="text" maxlength="1000" [(ngModel)]="search_params['consumer_first_name']"></ion-input>
        </ion-item>
        <ion-item lines="full">
            <ion-label position="stacked">Customer last name</ion-label>
            <ion-input type="text" inputmode="text" maxlength="1000" [(ngModel)]="search_params['consumer_last_name']"></ion-input>
        </ion-item>
        <!-- 
            <ion-item lines="full">
                <ion-label position="stacked">From date</ion-label>
                <ion-datetime [(ngModel)]="search_params['from_date']" cancelText="Clear" doneText="OK" (ionCancel)="clearFromDate()" displayFormat="D MMM YYYY, HH:mm"></ion-datetime>
            </ion-item>
            <ion-item lines="full">
                <ion-label position="stacked">To date</ion-label>
                <ion-datetime [(ngModel)]="search_params['to_date']" cancelText="Clear" doneText="OK" (ionCancel)="clearToDate()" displayFormat="D MMM YYYY, HH:mm"></ion-datetime>
            </ion-item> 
        -->
    </ion-list>
</ion-content>
