import { Storage } from '@capacitor/storage';
import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

	constructor() { }

	public async set(key: string, value: any): Promise<void> {
		await Storage.set({
			key: key,
			value: JSON.stringify(value)
		});
	}

	public async get(key: string): Promise<any> {
		const item = await Storage.get({ key: key });
		return JSON.parse(item.value);
	}

	public async remove(key: string): Promise<void> {
		await Storage.remove({
			key: key
		});
	}

	public async clear(): Promise<void> {
		await Storage.clear();
	}

}
