import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';

@Component({
    selector: 'app-transaction-search',
    templateUrl: './transaction-search.page.html',
    styleUrls: ['./transaction-search.page.scss'],
})
export class TransactionSearchPage implements OnInit {

    search_params = {};

    constructor(
        public modalController: ModalController,
        public alertController: AlertController,
        public navParams: NavParams,
        public loadingController: LoadingController,
    ) { }

    ngOnInit() {

        if (this.navParams.get('number')) {
            this.search_params['number'] = this.navParams.get('number');
        }
        if (this.navParams.get('consumer_first_name')) {
            this.search_params['consumer_first_name'] = this.navParams.get('consumer_first_name');
        }
        if (this.navParams.get('consumer_last_name')) {
            this.search_params['consumer_last_name'] = this.navParams.get('consumer_last_name');
        }
        if (this.navParams.get('from_date')) {
            this.search_params['from_date'] = this.navParams.get('from_date');
        }
        if (this.navParams.get('to_date')) {
            this.search_params['to_date'] = this.navParams.get('to_date');
        }

    }

    close() {
        this.modalController.dismiss();
    }

    filterTransactions() {
        this.modalController.dismiss(this.search_params);
    }

    clearTransactionNumber() {
        delete this.search_params['transaction_number'];
    }

    clearFirstName() {
        delete this.search_params['first_name'];
    }

    clearLastName() {
        delete this.search_params['last_name'];
    }

    clearFromDate() {
        delete this.search_params['from_date'];
    }

    clearToDate() {
        delete this.search_params['to_date'];
    }

}
